import { parsePhoneNumberFromString } from 'libphonenumber-js';

// Format phone number for all countries
export const formatPhoneAllCountry = (phone: string): string | undefined => {
    if (!phone) return;

    const phoneNumber = parsePhoneNumberFromString(phone);
    return phoneNumber?.formatInternational();
};

// Format phone number
export const formatPhone = (phone: string): string => {
    if (!phone) return '';

    let result = '';
    const value = phone.replace(/\D+/g, '');
    const numberLength = 11;

    for (let i = 0; i < value.length && i < numberLength; i++) {
        switch (i) {
            case 0:
                result += '+ ' + value[i];
                continue;
            case 1:
                result += ' ' + value[i];
                continue;
            case 4:
                result += ' ';
                break;
            case 7:
                result += ' ';
                break;
            case 9:
                result += ' ';
                break;
            default:
                break;
        }
        result += value[i];
    }

    return result;
};

// Mask hidden phone number
export const maskHiddenPhone = (phone: string): string => {
    if (!phone) return '';

    let result = '';
    const value = phone.replace(/\D+/g, '');
    const numberLength = 11;

    for (let i = 0; i < value.length && i < numberLength; i++) {
        switch (i) {
            case 0:
                result += '+ ' + value[i];
                continue;
            case 1:
                result += ' ' + value[i];
                continue;
            case 4:
                result += ' ';
                continue;
            case 5:
                result += '*';
                continue;
            case 6:
                result += '*';
                continue;
            case 7:
                result += '* ';
                continue;
            case 8:
                result += '*';
                continue;
            case 9:
                result += '* ';
                break;
            default:
                break;
        }
        result += value[i];
    }

    return result;
};

import './Contact.scss';
import { ReactComponent as CityBikeIcon } from "../../../assets/icons/contacticon.svg";
import { useTranslation } from 'react-i18next';

const Contact = () => {
    const { t } = useTranslation();

    return (
        <div id='contactsSection' className="contact__wrapper">
            <div className="contact__text-section">
                <h1 className="contact__title">{t('contact.title')}</h1>
                <p className="contact__subtitle">{t('contact.subtitle')}</p>
            </div>
            <div className="contact__form-section">
                <form className="contact__form">
                    <div className="contact__input-group">
                        <label htmlFor="email">{t('contact.emailLabel')}</label>
                        <input type="email" id="email" placeholder={t('contact.emailPlaceholder')} />
                    </div>
                    <div className="contact__input-group">
                        <label htmlFor="comment">{t('contact.commentLabel')}</label>
                        <textarea id="comment" placeholder={t('contact.commentPlaceholder')} className="contact__textarea"/>
                    </div>
                    <button type="submit" className="contact__submit-button">{t('contact.submitButton')}</button>
                </form>
                <div className="contact__icon">
                    <CityBikeIcon />
                </div>
            </div>
        </div>
    )
}

export default Contact;

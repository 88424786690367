import "./OurBikes.scss"
import { ReactComponent as OurBikeBlackIcon } from "../../../assets/icons/ourBike_black.svg"
import { ReactComponent as OurBikeWhiteIcon } from "../../../assets/icons/ourBike_white.svg"
import { useState } from "react"
import Modal from "../../simples/Modal/Modal"
import Form from "../../simples/Form/Form"
import { useTranslation } from "react-i18next"

const OurBikes = () => {
	const { t } = useTranslation()
	const [isModalOpen, setModalOpen] = useState(false)

	const openModal = () => setModalOpen(true)
	const closeModal = () => setModalOpen(false)

	return (
		<div id='feedbackSection' className='container'>
			<div className='our-bikes__inner'>
				<span className='our-bikes__title'>{t("ourBikes.title")}</span>
				<div className='our-bikes__content'>
					<div className='our-bikes__item'>
						<OurBikeBlackIcon className='our-bikes__icon' />
						<span className='our-bikes__description'>
							{t("ourBikes.description1")}
						</span>
						<button className='our-bikes__button' onClick={openModal}>
							{t("ourBikes.buyButton")}
						</button>
					</div>
					<div className='our-bikes__item'>
						<OurBikeWhiteIcon className='our-bikes__icon' />
						<span className='our-bikes__description'>
							{t("ourBikes.description2")}
						</span>
						<button className='our-bikes__button' onClick={openModal}>
							{t("ourBikes.buyButton")}
						</button>
					</div>
				</div>
			</div>
			<Modal isOpen={isModalOpen} onClose={closeModal}>
				<h2 className='our-bikes__modal-title'>{t("ourBikes.modalTitle")}</h2>
				<Form />
			</Modal>
		</div>
	)
}

export default OurBikes
